import React from 'react'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Span from '@component/Text/Span'

export type MarkdownClassName = {
    h1?: string
    h2?: string
    h3?: string
    h4?: string
    h5?: string
    h6?: string
    p?: string
    strong?: string
    li?: string
    ul?: string
    ol?: string
    a?: string
    code?: string
    img?: string
    imgContainer?: string
    imgAlt?: string
}

interface IProps {
    className?: string
    content?: string
    customClassName?: MarkdownClassName
}

const WizMarkdown: React.FC<IProps> = ({className, content, customClassName}) => {
    return (
        <ReactMarkdown
            className={className}
            components={{
                h1: ({node, ...props}) => (
                    <h1
                        {...props}
                        className={`font-sans text-h1 text-gray01 dark:text-dark_gray01 pt-[10.92px] mb-[3.08px] ${customClassName?.h1}`}
                    />
                ),
                h2: ({node, ...props}) => (
                    <h2
                        {...props}
                        className={`font-sans text-h2 text-gray01 dark:text-dark_gray01 pt-[11.43px] mb-[2.57px] ${customClassName?.h2}`}
                    />
                ),
                h3: ({node, ...props}) => (
                    <h3
                        {...props}
                        className={`font-sans text-h3 text-gray01 dark:text-dark_gray01 pt-[11.9px] mb-[1.064px] ${customClassName?.h3}`}
                    />
                ),
                h4: ({node, ...props}) => (
                    <h4
                        {...props}
                        className={`font-sans text-h4 text-gray01 dark:text-dark_gray01 pt-[6.3px] mb-[7.65px] ${customClassName?.h4}`}
                    />
                ),
                h5: ({node, ...props}) => (
                    <h5
                        {...props}
                        className={`font-sans text-ti1 text-gray01 dark:text-dark_gray01 pt-[6.3px] mb-[7.65px] ${customClassName?.h5}`}
                    />
                ),
                h6: ({node, ...props}) => (
                    <h6
                        {...props}
                        className={`font-sans text-ti2 text-gray01 dark:text-dark_gray01 pt-[6.3px] mb-[7.65px] ${customClassName?.h6}`}
                    />
                ),
                p: ({node, ...props}) => (
                    <p
                        {...props}
                        className={`font-sans text-body1 text-gray01 dark:text-dark_gray01 pt-[9.24px] mb-[18.76px] ${customClassName?.p}`}
                    />
                ),
                strong: ({node, ...props}) => (
                    <strong {...props} className={`font-sans font-bold ${customClassName?.strong}`} />
                ),
                li: ({node, ...props}) => {
                    return (
                        <li
                            {...props}
                            className={`list-disc list-outside text-gray01 dark:text-dark_gray01 ${customClassName?.li}`}
                        />
                    )
                },
                ul: function CustomUL({node, ...props}) {
                    return (
                        <ul
                            {...props}
                            className={`list-disc list-outside mt-[14px] mb-[11.76px] pt-[2.24px] pl-[40px] ${customClassName?.ul}`}
                        />
                    )
                },
                ol: function CustomOL({node, ...props}) {
                    return (
                        <ol
                            {...props}
                            className={`list-decimal list-outside mt-[14px] mb-[11.76px] pt-[2.24px] pl-[40px] ${customClassName?.ol}`}
                        />
                    )
                },

                a: ({node, ...props}) => {
                    return (
                        <a
                            {...props}
                            target="_blank"
                            className={`font-sans text-gray01 dark:text-dark_gray01 hover:text-primary_shade dark:hover:text-dark_primary_shade underline ${
                                node.children[0].type === 'element' && 'flex justify-center'
                            } ${customClassName?.a}`}
                        />
                    )
                },
                code: ({node, className, children, ...props}) => {
                    return (
                        <code
                            {...props}
                            className={`bg-gray07 dark:bg-dark_gray07 px-[5px] py-[2px] rounded-[8px] ${customClassName?.code}`}>
                            {children}
                        </code>
                    )
                },
                pre: ({node, className, children, ...props}) => {
                    return (
                        <pre
                            {...props}
                            className={`bg-gray07 dark:bg-dark_gray07 overflow-auto py-[15px] px-[20px] break-all mb-[18.76px] ${customClassName?.code}`}>
                            {children}
                        </pre>
                    )
                },
                img: ({node, ...props}) => {
                    if (node.tagName !== 'img') return null
                    const metastring = node.properties.alt as string
                    const alt = metastring?.replace(/ *\{[^)]*\} */g, '')
                    const metaWidth = metastring.match(/{([^}]+)x/)
                    const metaHeight = metastring.match(/x([^}]+)}/)
                    const width = metaWidth ? `w-[${metaWidth.at(1)}px]` : ''
                    const height = metaHeight ? `h-[${metaHeight.at(1)}px]` : ''
                    const hasAlign = metastring?.toLowerCase().includes('{align: ')
                    const align = metastring?.match(/{align: (.*?)}/)?.pop()
                    const getAlign = () => {
                        if (!hasAlign) return 'flex flex-col items-center'
                        switch (align) {
                            case 'left':
                                return 'flex flex-col items-start'
                            case 'center':
                                return 'flex flex-col items-center'
                            case 'right':
                                return 'flex flex-col items-end'
                        }
                    }
                    return (
                        <Span className={`${getAlign()} mb-[30px] ${customClassName?.imgContainer}`}>
                            <img
                                src={`${props?.src}?type=webp&quality=100&action=fit`}
                                alt={alt ? alt : 'image'}
                                width={1920}
                                height={720}
                                loading={'lazy'}
                                className={`${width} ${height} min-h-[363px] md:min-h-[auto] mb-[10px] ${customClassName?.img}`}
                            />
                            {alt && (
                                <Span
                                    className={`text-body2 text-gray03 dark:text-dark_gray03 text-${align} ${customClassName?.imgAlt}`}>
                                    {alt}
                                </Span>
                            )}
                        </Span>
                    )
                },
            }}
            remarkPlugins={[remarkGfm]}>
            {content}
        </ReactMarkdown>
    )
}
export default WizMarkdown
